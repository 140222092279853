import React from "react";
import PropTypes from "prop-types";

import ProjectDeployKey from "Containers/ProjectDeployKey";
import ContentLayout from "Components/ContentLayout";

class ProjectSettingsDeployKey extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ContentLayout className="settings">
        <ProjectDeployKey
          organizationId={this.props.params.organizationId}
          projectId={this.props.params.projectId}
        />
      </ContentLayout>
    );
  }
}

ProjectSettingsDeployKey.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired
  })
};

export default ProjectSettingsDeployKey;
